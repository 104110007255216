<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap>
            <v-col cols="10">
                <!-- Page view -->
                <router-view :key="reloadPage" @districts-count="districtsCounting" @update-view="refreshView"></router-view>
                <!-- end -->
            </v-col>
            <v-col cols="2">
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>

  export default {
    name: 'Districts',
    data() {
      return {
        applyFilters: null,
        reloadPage: 0,
        countingDistricts: 0
      }
    },
    methods:{
      refreshView(){
        this.reloadPage++
      },
      filterSelected(val) {
          this.reloadPage += 1
          this.applyFilters = val
      },
      districtsCounting(v){
        this.countingDistricts = v
      }
    }
  }
</script>
